// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.e6WzhijFwmQCoSlnfFTo {
  line-height: 24px;
}
.e6WzhijFwmQCoSlnfFTo .Ufbl4lhSlDksKSVSJjAM {
  padding: 30px 47px;
}
.e6WzhijFwmQCoSlnfFTo .g_46fcmsoGCDPLq4gtlw {
  font-weight: 600;
  font-size: 16px;
  color: #262626;
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.e6WzhijFwmQCoSlnfFTo .ZvZ4kz7RCSibJZtOISKw {
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #595959;
}
.e6WzhijFwmQCoSlnfFTo .ok_HOk3zTxX62lGGFork {
  display: flex;
  flex-direction: row;
  justify-content: end;
}
.e6WzhijFwmQCoSlnfFTo .ok_HOk3zTxX62lGGFork button {
  border-radius: 2px;
  padding: 5px 16px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
.e6WzhijFwmQCoSlnfFTo .ok_HOk3zTxX62lGGFork .LmKWMeIMKfDi2sjuX3Pg {
  background-color: #2755fe;
  color: #ffffff;
  cursor: pointer;
}
.e6WzhijFwmQCoSlnfFTo .ok_HOk3zTxX62lGGFork .v5mVfs0lN0CQGi1fSzxg {
  background: #f5f5f5;
  color: #bfbfbf;
  border: 1px solid #d9d9d9;
  cursor: not-allowed;
}
.e6WzhijFwmQCoSlnfFTo .ok_HOk3zTxX62lGGFork .WTHr1ZaNsXTPuRlqjNiX {
  background: #ffffff;
  color: #2755fe;
  border: none;
  box-shadow: none;
}
.e6WzhijFwmQCoSlnfFTo .ok_HOk3zTxX62lGGFork .b_93eDRfXAQZQ9uJQKIl {
  background: #ffffff;
  color: #2755fe;
  border: none;
  box-shadow: none;
  cursor: not-allowed;
}`, "",{"version":3,"sources":["webpack://./src/components/Bgv/CommonComponent/BGVConfirmDialog/BGVConfirmDialog.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;AAAE;EACE,kBAAA;AAEJ;AAAE;EACE,gBAAA;EACA,eAAA;EACA,cAAA;EACA,aAAA;EACA,mBAAA;EACA,SAAA;AAEJ;AAAE;EACE,mBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAEJ;AAAE;EACE,aAAA;EACA,mBAAA;EACA,oBAAA;AAEJ;AAAI;EACE,kBAAA;EACA,iBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;AAEN;AAAI;EACE,yBAAA;EACA,cAAA;EACA,eAAA;AAEN;AAAI;EACE,mBAAA;EACA,cAAA;EACA,yBAAA;EACA,mBAAA;AAEN;AAAI;EACE,mBAAA;EACA,cAAA;EACA,YAAA;EACA,gBAAA;AAEN;AAAI;EACE,mBAAA;EACA,cAAA;EACA,YAAA;EACA,gBAAA;EACA,mBAAA;AAEN","sourcesContent":[".consentConfirmModal {\n  line-height: 24px;\n  .confirModalBody {\n    padding: 30px 47px;\n  }\n  .confirmModalHeading {\n    font-weight: 600;\n    font-size: 16px;\n    color: #262626;\n    display: flex;\n    flex-direction: row;\n    gap: 10px;\n  }\n  .confirmText {\n    font-weight: normal;\n    font-size: 14px;\n    line-height: 22px;\n    color: #595959;\n  }\n  .confirmModalFooter {\n    display: flex;\n    flex-direction: row;\n    justify-content: end;\n\n    button {\n      border-radius: 2px;\n      padding: 5px 16px;\n      font-weight: 500;\n      font-size: 16px;\n      line-height: 24px;\n    }\n    .activeConfirmBtn {\n      background-color: #2755fe;\n      color: #ffffff;\n      cursor: pointer;\n    }\n    .disabledConfirmBtn {\n      background: #f5f5f5;\n      color: #bfbfbf;\n      border: 1px solid #d9d9d9;\n      cursor: not-allowed;\n    }\n    .cancelConfirmBtn {\n      background: #ffffff;\n      color: #2755fe;\n      border: none;\n      box-shadow: none;\n    }\n    .disabledCancelConfirmBtn {\n      background: #ffffff;\n      color: #2755fe;\n      border: none;\n      box-shadow: none;\n      cursor: not-allowed;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"consentConfirmModal": `e6WzhijFwmQCoSlnfFTo`,
	"confirModalBody": `Ufbl4lhSlDksKSVSJjAM`,
	"confirmModalHeading": `g_46fcmsoGCDPLq4gtlw`,
	"confirmText": `ZvZ4kz7RCSibJZtOISKw`,
	"confirmModalFooter": `ok_HOk3zTxX62lGGFork`,
	"activeConfirmBtn": `LmKWMeIMKfDi2sjuX3Pg`,
	"disabledConfirmBtn": `v5mVfs0lN0CQGi1fSzxg`,
	"cancelConfirmBtn": `WTHr1ZaNsXTPuRlqjNiX`,
	"disabledCancelConfirmBtn": `b_93eDRfXAQZQ9uJQKIl`
};
export default ___CSS_LOADER_EXPORT___;
