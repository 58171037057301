// Import libraries
import React, { useState } from 'react';
import { Card, Spinner } from 'react-bootstrap';

// Import styles
import styles from './CandidateAnalytics.module.scss';

// Import constants
const commonConstants = require('../utils/CommonConstants');

// Helper functions
const getTrackerLink = (env, companyId, userId) => {
  const trackerId = commonConstants.trackerInfo[env];
  const baseUrl = `https://app.appsmith.com/app/trackers/23610-dev-testing-677d4f2869821c74143922f5`;
  const params = new URLSearchParams({
    companyId,
    embed: 'true',
    ...(userId && { userId })
  });

  return `${baseUrl}?${params.toString()}`;

  // Commented out as the above tracker is the testing-tracker
  //return `https://app.appsmith.com/app/trackers/${env}-template-tracker-${trackerId}?companyId=${companyId}&embed=true`;
};

function CandidateAnalytics(props) {
  // State to manage loading
  const [loading, setLoading] = useState(true);

  // Local constants
  const companyId = props?.profile?.profile?.company_user_mapping?.company_id || props?.companyId;
  const { access_level, user_id } = props?.profile?.profile?.company_user_mapping?.company;
  const env = commonConstants.envMapping[props?.env] || commonConstants.envMapping.development;
  const appSmithWidgetLink = getTrackerLink(
    env,
    companyId,
    parseInt(access_level, 10) === 5 ? null : user_id
  );

  // Handler functions
  const handleIframeLoad = () => {
    setLoading(false);
  };

  return (
    <div>
      <div
        className={`${styles.parentDivWrapper} ${
          props?.isSuperAdmin ? null : styles.clientSideDimensions
        }`}
      >
        <>
          <div className={styles.header}>
            <div className={styles.heading}>{'Candidate Analytics'}</div>
          </div>
          <Card className={styles.bodyCard}>
            {loading && (
              <div className={`d-flex justify-content-center align-items-center ${styles.spinner}`}>
                <Spinner animation='border' variant='primary' />
              </div>
            )}
            <div className={`${styles.iframeDiv} ${loading ? styles.hidden : ''}`}>
              <iframe
                src={appSmithWidgetLink}
                className={styles.iframe}
                title='Appsmith Widget'
                onLoad={handleIframeLoad}
              />
            </div>
          </Card>
        </>
      </div>
    </div>
  );
}

export default CandidateAnalytics;
